let parseToShortFloat = (val) =>{
    if (val && parseFloat(val)){
        return parseFloat(val).toFixed(2)
    }
    return 0;
}
let positiveNumber = (event) =>{
    let _keys = ['-', '+', 'e'];
    return  _keys.includes(event.key) ? event.preventDefault() : null
}
let parseToShortFloatFixed = (val, digits = 3) =>{
    if (val && parseFloat(val)){
        if (!digits) digits = 3;
        const pow = 10 ** digits
        return (Math.trunc(val * pow) / pow).toString();
    }
    return 0;
}
let defaultValueAfterDot = () =>{
    return '0.001';
}
export default {parseToShortFloat, positiveNumber, parseToShortFloatFixed, defaultValueAfterDot};